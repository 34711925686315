import React, { useState } from 'react';
import Dialog from '@reach/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { H1, H2, P, Table } from '../styled/GlobalElements';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentGroupId,
  selectCurrentGroupInfo,
} from '../selectors/groups';
import { selectModelPortfolios } from '../selectors/modelPortfolios';
import { GreyBox } from './ModelPortfolio/SelectGroupDialog';
import { Position } from '../types/groupInfo';
import { selectAssetClasses } from '../selectors/assetClasses';
import { postData } from '../api';
import {
  loadAssetClasses,
  loadGroupInfo,
  loadModelPortfolios,
} from '../actions';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { ReactLoadingDiv } from './NewAssetsDetected';

type Props = {
  showChooseAssetClass: boolean;
  closeModal: () => void;
  targetSelected: Position | undefined;
};

const SelectAssetClassModal = ({
  showChooseAssetClass,
  closeModal,
  targetSelected,
}: Props) => {
  const dispatch = useDispatch();

  const currentGroupId = useSelector(selectCurrentGroupId);
  const currentGroupInfo = useSelector(selectCurrentGroupInfo);
  const modelPortfolios = useSelector(selectModelPortfolios);
  const assetClasses = useSelector(selectAssetClasses);

  const modelId = currentGroupInfo?.model_portfolio?.id;

  const currentModel = modelPortfolios.filter(
    (model) => model.model_portfolio.id === modelId,
  )[0];
  const usedAssetClassesIds = currentModel.model_portfolio_asset_class.map(
    (assetClass) => assetClass.model_asset_class.id,
  );

  const [loading, setLoading] = useState(false);

  const applyToModel = (assetClassId: string) => {
    setLoading(true);
    if (!targetSelected) {
      return;
    }
    let assetClass = assetClasses.filter(
      (astCls) => astCls.model_asset_class.id === assetClassId,
    )[0];
    assetClass.model_asset_class_target.push(targetSelected);
    postData(`/api/v1/modelAssetClass/${assetClassId}`, assetClass)
      .then(() => {
        dispatch(loadAssetClasses());
        dispatch(loadGroupInfo());
      })
      .catch(() => {
        dispatch(loadAssetClasses());
        setLoading(false);
        toast.error(
          `${assetClass.model_asset_class.name} asset class update failed`,
        );
      });

    // if asset class is not in the model, we have to first add the asset class to model and then reapply the model
    if (!usedAssetClassesIds.includes(assetClassId)) {
      const classes = currentModel.model_portfolio_asset_class;
      const newAssetClass = {
        model_asset_class: assetClass.model_asset_class,
        percent: '0',
      };
      classes.push(newAssetClass);
      const modelPortfolio = currentModel;
      modelPortfolio.model_portfolio_asset_class = classes;
      postData(`/api/v1/modelPortfolio/${modelId}`, modelPortfolio)
        .then(() => {
          dispatch(loadModelPortfolios());
        })
        .catch((err) => {
          dispatch(loadModelPortfolios());
          setLoading(false);
          if (err.response) {
            toast.error(err.response.data.detail);
          }
        });
    }
    postData(
      `api/v1/portfolioGroups/${currentGroupId}/modelPortfolio/${modelId}`,
      {},
    )
      .then(() => {
        dispatch(loadGroupInfo());
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          toast.error(err.response.data.detail);
        }
      });
  };

  return (
    <Dialog
      isOpen={showChooseAssetClass}
      onDismiss={closeModal}
      aria-labelledby="dialog1Title"
      aria-describedby="dialog1Desc"
    >
      {loading ? (
        <ReactLoadingDiv>
          <ReactLoading type="bubbles" width={60} color="var(--brand-green)" />
        </ReactLoadingDiv>
      ) : (
        <>
          <button onClick={closeModal} style={{ float: 'right' }}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
          <br />
          <H1>
            Add <strong>"{targetSelected?.symbol.symbol}"</strong> to:
          </H1>
          {assetClasses.map((astCls) => {
            const id = astCls.model_asset_class.id;
            return (
              <GreyBox key={id} onClick={() => applyToModel(id)}>
                <Table>
                  <H2>{astCls.model_asset_class.name}</H2>
                  {usedAssetClassesIds.includes(id) && <P>Part of the model</P>}
                </Table>
              </GreyBox>
            );
          })}
        </>
      )}
    </Dialog>
  );
};

export default SelectAssetClassModal;
