import styled from '@emotion/styled';
import React from 'react';

const Overlay = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #e9e7e7;
  opacity: 0.7;
`;

const Inner = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Content = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  span {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: white;
    border-top-color: var(--brand-green);
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const OverlayLoading = () => {
  return (
    <Overlay>
      <Inner>
        <Content>
          <span></span>
        </Content>
      </Inner>
    </Overlay>
  );
};

export default OverlayLoading;
