import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { StripeProvider } from 'react-stripe-elements';
import '@reach/menu-button/styles.css';
import '@reach/dialog/styles.css';
import {
  selectIsDemo,
  selectLoggedIn,
  selectReferralCode,
  selectTrackingId,
} from '../selectors';
import { selectShowInsecureApp, selectShowSecureApp } from '../selectors/app';
import { generateTrackingCode } from '../seo';
import { setReferralCode, setTrackingId } from '../actions';
import { selectQueryTokens } from '../selectors/router';
import {
  LOGIN_PATH,
  REGISTER_PATH,
  HELP_PATH,
  RESET_PASSWORD_PATH,
  DASHBOARD_PATH,
  GROUP_PATH,
  SETTINGS_PATH,
  REFERRALS_PATH,
  REPORTING_PATH,
  GOALS_PATH,
  MY_MODELS_PATH,
  CONTACT_FORM_PATH,
  RECOVERY_PATH,
} from './Paths';
import { selectIsPaid } from '../selectors/subscription';
import ReactLoading from 'react-loading';
import useLogin from '../hooks/login';
import useRegistration from '../hooks/registration';

// preload pages
const ReactLazyPreload = (importStatement: any) => {
  const Component = React.lazy(importStatement);
  //@ts-ignore
  Component.preload = importStatement;
  return Component;
};

// code splitting to load our pages
const LoginPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "login" */ '../pages/LoginPage'),
);

const RegistrationPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "registration" */ '../pages/RegistrationPage'),
);

const DemoLoginPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "demo-login" */ '../pages/DemoLoginPage'),
);

const HelpPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "help" */ '../pages/HelpPage'),
);

const ContactFormPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "contact-form" */ '../components/Help/ContactForm'
  ),
);

const ResetPasswordPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "reset-password" */ '../pages/ResetPasswordPage'),
);

const ResetPasswordConfirmPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "reset-password-confirm" */ '../pages/ResetPasswordConfirmPage'
  ),
);

const SetNewPasswordPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "set-new-password" */ '../pages/SetNewPasswordPage'
  ),
);
const ConnectionCompletePage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "brokerage-oauth" */ '../pages/ConnectionCompletePage'
  ),
);

const WelcomePage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "settings" */ '../pages/WelcomePage'),
);

const UpgradeOfferPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "upgrade-offer" */ '../pages/UpgradeOfferPage'),
);

const LoginLoadingPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "login-loading" */ '../pages/LoginLoadingPage'),
);

const DashboardPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "dashboard" */ '../pages/DashboardPage'),
);

const GroupPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "group" */ '../pages/GroupPage'),
);

const CouponPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "coupon" */ '../pages/CouponPage'),
);

const RenewPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "renew" */ '../pages/RenewPage'),
);

const ConnectionPortalPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "connection-portal" */ '../pages/ConnectionPortalPage'
  ),
);

const SettingsPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "settings" */ '../pages/SettingsPage'),
);

const ReferralPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "referrals" */ '../pages/ReferralPage'),
);

const UpgradePage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "upgrade" */ '../pages/UpgradePage'),
);

const PerformancePage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "performance" */ '../pages/PerformancePage'),
);

const GoalsPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "goals" */ '../pages/GoalsPage'),
);

const GoalDetailPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "goals-detail" */ '../pages/GoalDetailPage'),
);

const MyModelPortfoliosPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "my-model-portfolios" */ '../pages/MyModelPortfoliosPage'
  ),
);
const AssetClassesPage = ReactLazyPreload(() =>
  import(/* webpackChunkName: "asset-class" */ '../pages/AssetClassesPage'),
);
const ModelPortfolioPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "model-portfolio" */ '../pages/ModelPortfolioPage'
  ),
);
const SharedModelPortfolio = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "shared-model-portfolio" */ '../components/ModelPortfolio/SharedModelPortfolio'
  ),
);
const Prioritization = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "prioritization" */ '../components/ModelPortfolio/Prioritization'
  ),
);

const EmailConfirmationPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "email-confirmation" */ '../pages/EmailConfirmationPage'
  ),
);

const RecoverAccountPage = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: "recover-account" */ '../pages/RecoverAccountPage'
  ),
);

// declare global {
//   interface Window {
//     Stripe: any;
//   }
// }

// list of all the routes that has any link associate with them in the app
type RouteType = {
  path: string;
  exact: boolean;
  component: any;
};
const routes = [
  { path: LOGIN_PATH, exact: true, component: LoginPage },
  { path: REGISTER_PATH, exact: true, component: RegistrationPage },
  { path: HELP_PATH, exact: true, component: HelpPage },
  { path: CONTACT_FORM_PATH, exact: true, component: ContactFormPage },
  { path: RESET_PASSWORD_PATH, exact: true, component: ResetPasswordPage },
  { path: DASHBOARD_PATH, exact: true, component: DashboardPage },
  { path: GROUP_PATH, exact: false, component: GroupPage },
  { path: SETTINGS_PATH, exact: true, component: SettingsPage },
  { path: REFERRALS_PATH, exact: true, component: ReferralPage },
  { path: REPORTING_PATH, exact: true, component: PerformancePage },
  { path: GOALS_PATH, exact: true, component: GoalsPage },
  { path: MY_MODELS_PATH, exact: true, component: MyModelPortfoliosPage },
  { path: RECOVERY_PATH, exact: true, component: RecoverAccountPage },
];

const findComponentForRoute = (path: string, routes: RouteType[]) => {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    }),
  );
  return matchingRoute ? matchingRoute.component : null;
};
export const preloadRouteComponent = (to: string) => {
  const component = findComponentForRoute(to, routes);
  if (component && component.preload) {
    component.preload();
  }
};

// use the stripe test key unless we're in prod
const stripePublicKey =
  process.env.REACT_APP_BASE_URL_OVERRIDE &&
  process.env.REACT_APP_BASE_URL_OVERRIDE === 'api.passiv.com'
    ? 'pk_live_LTLbjcwtt6gUmBleYqVVhMFX'
    : 'pk_test_UEivjUoJpfSDWq5i4xc64YNK';

const questradeOauthRedirect = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let newPath = '/oauth/questrade?' + urlParams;
  return <Redirect to={newPath} />;
};

const sharedModelRedirect = () => {
  let newPath = '/shared-model-portfolio?share=';
  return <Redirect to={newPath} />;
};

const App = () => {
  const showInsecureApp = useSelector(selectShowInsecureApp);
  const showSecureApp = useSelector(selectShowSecureApp);
  const referralCode = useSelector(selectReferralCode);
  const trackingId = useSelector(selectTrackingId);
  const loggedIn = useSelector(selectLoggedIn);
  const location = useLocation();
  const dispatch = useDispatch();
  const isPaid = useSelector(selectIsPaid);
  const isDemo = useSelector(selectIsDemo);
  const queryParams = useSelector(selectQueryTokens);
  const login = useLogin();
  const registration = useRegistration();

  let updateQuery = false;

  // redirect the old path name with '/app'
  if (location.pathname.includes('/app')) {
    let newPath = location.pathname.replace('/app', '');
    if (newPath.length === 0) {
      newPath = '/';
    }

    if (Object.keys(queryParams).length > 0) {
      const newQuery = qs.stringify(queryParams);
      newPath += '?' + newQuery;
    }

    window.location.replace(newPath);
  }

  // extract referral code (if any) and make available on registration page
  if (queryParams.ref) {
    if (queryParams.ref !== referralCode) {
      dispatch(setReferralCode({ referralCode: queryParams.ref }));
    }
    delete queryParams.ref;
    updateQuery = true;
  }

  // extract tracking id (if any) and make available on registration page
  if (queryParams.uid) {
    if (queryParams.uid !== trackingId) {
      dispatch(setTrackingId({ trackingId: queryParams.uid }));
    }
    delete queryParams.uid;
    updateQuery = true;
  } else {
    if (trackingId === '') {
      dispatch(setTrackingId({ trackingId: generateTrackingCode() }));
    }
  }

  // include query params in deep link redirect for insecure app
  if (queryParams.next) {
    delete queryParams.next;
    updateQuery = true;
  }
  let appendParams = '';
  if (Object.keys(queryParams).length > 0) {
    const pieces = Object.entries(queryParams).map(([k, v]) => {
      return `${k}%3D${v}`;
    });
    appendParams = '%3F' + pieces.join('%26');
  }

  // redirect path for secure app
  let redirectPath = '/dashboard';
  if (location && location.search) {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (params.next) {
      redirectPath = params.next as string;
      queryParams.next = redirectPath;
    }
    if (params.code) {
      queryParams.code = params.code;
    }
  }

  if (updateQuery) {
    const newQuery = qs.stringify(queryParams);
    let newPath = location.pathname;
    if (newQuery) {
      newPath += '?' + newQuery;
    }
    window.history.replaceState({}, '', newPath);
  }

  // stripe provider
  const [stripe, setStripe] = useState<any>(null);
  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(stripePublicKey));
    } else {
      document.querySelector('#stripe-js')!.addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(stripePublicKey));
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleLoginSubmit = async (values: any, actions: any) => {
    const mfa = await login(values, actions);
    return mfa;
  };

  const handleRegistrationSubmit = async (values: any, actions: any) => {
    await registration(values, actions);
  };

  return (
    <Layout>
      <StripeProvider stripe={stripe}>
        <React.Suspense
          fallback={
            <ReactLoading type="spin" color="var(--brand-green)" width={30} />
          }
        >
          <Switch>
            {/* common routes - Passiv App */}

            {<Route path="/help" component={HelpPage} />}
            {<Route path="/contact-form" component={ContactFormPage} />}
            {<Route path="/reset-password" component={ResetPasswordPage} />}
            {<Route path="/confirm-email" component={EmailConfirmationPage} />}
            {
              <Route
                path="/reset-password-confirm/:token"
                component={ResetPasswordConfirmPage}
              />
            }
            {
              <Route
                path="/set-new-password/:token"
                component={SetNewPasswordPage}
              />
            }
            {<Route path="/recover-account" component={RecoverAccountPage} />}
            {<Route path="/demo" component={DemoLoginPage} />}
            {
              <Route
                path="/shared-model-portfolio"
                component={SharedModelPortfolio}
                render={() => sharedModelRedirect()}
              />
            }
            {loggedIn && (
              <Route
                exact
                path="/oauth/questrade-trade"
                render={() => questradeOauthRedirect()}
              />
            )}
            {showSecureApp && (
              <Route
                path="/connection-complete/:brokerageName"
                component={ConnectionCompletePage}
              />
            )}
            {showSecureApp && (
              <Route path="/welcome">
                <WelcomePage />
              </Route>
            )}
            {showSecureApp && (
              <Route path="/connect/:openBrokerage?">
                <ConnectionPortalPage usedIn="onboarding" />
              </Route>
            )}
            {showSecureApp && (
              <Route path="/settings/connect/:openBrokerage?">
                <ConnectionPortalPage usedIn="settings" />
              </Route>
            )}
            {showSecureApp && (
              <Route path="/settings" component={SettingsPage} />
            )}
            {showSecureApp && (
              <Route path="/referrals" component={ReferralPage} />
            )}
            {showSecureApp && <Route path="/upgrade" component={UpgradePage} />}
            {showSecureApp && <Route path="/coupon" component={CouponPage} />}
            {showSecureApp && <Route path="/renew" component={RenewPage} />}
            {/* secure app */}
            {showSecureApp && (
              <Route path="/reporting" component={PerformancePage} />
            )}
            {showSecureApp && <Route path="/goals" component={GoalsPage} />}
            {showSecureApp && (
              <Route path="/goal/:goalId" component={GoalDetailPage} />
            )}
            {showSecureApp && (
              <Route path="/performance">
                <Redirect to="/reporting" />
              </Route>
            )}
            {showSecureApp && (
              <Route
                exact
                path="/questrade-offer"
                component={UpgradeOfferPage}
              />
            )}
            {showSecureApp && (
              <Route exact path="/unocoin-offer" component={UpgradeOfferPage} />
            )}
            {loggedIn && (
              <Route
                exact
                path="/loading"
                render={(props) => (
                  <LoginLoadingPage {...props} redirectPath={redirectPath} />
                )}
              />
            )}
            {showSecureApp && (
              <Route path="/" exact>
                <Redirect to="/dashboard" />
              </Route>
            )}
            {showSecureApp && (
              <Route path="/dashboard" component={DashboardPage} />
            )}
            {showSecureApp && (
              <Route path="/group/:groupId" component={GroupPage} />
            )}
            {showSecureApp && (isPaid || isDemo) && (
              <Route path="/asset-classes" component={AssetClassesPage} />
            )}
            {showSecureApp && (
              <Route path="/models" component={MyModelPortfoliosPage} />
            )}
            {showSecureApp && (
              <Route
                exact
                path="/model-portfolio/:modelId"
                component={ModelPortfolioPage}
              />
            )}
            {showSecureApp && (
              <Route
                exact
                path="/model-portfolio/:modelId/group/:groupId"
                component={ModelPortfolioPage}
              />
            )}
            {showSecureApp && (isPaid || isDemo) && (
              <Route
                exact
                path="/priorities/:groupId"
                component={() => <Prioritization onSettingsPage={false} />}
              />
            )}

            {/* insecure app */}
            {showInsecureApp && (
              <Route
                path="/login"
                component={() => <LoginPage onSubmit={handleLoginSubmit} />}
              />
            )}
            {showInsecureApp && (
              <Route
                path="/register"
                component={() => (
                  <RegistrationPage onSubmit={handleRegistrationSubmit} />
                )}
              />
            )}
            {/* catchalls // when logged in, catch unknown URLs and redirect to
            dashboard or 'next' query param if defined */}
            {showSecureApp && (
              <Route path="*">
                <Redirect to={redirectPath} />
              </Route>
            )}
            {/* when not logged in, catch unknown URLs (such as secure paths) and
            login with redirect */}
            {showInsecureApp && (
              <Route path="*">
                <Redirect
                  to={`/login?next=${location.pathname}${appendParams}`}
                />
              </Route>
            )}
          </Switch>
        </React.Suspense>
      </StripeProvider>
    </Layout>
  );
};

export default App;
