import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

const initialState = {
  immediateRedirect: '',
  customRedirect: '',
  iframe: 'false',
  connectionType: '',
};

const queryParams = (state = initialState, action: any) => {
  const params = new URLSearchParams(window.location.search);
  const customRedirect = params.get('customRedirect');

  const validParams = {
    immediateRedirect:
      params.get('immediateRedirect') ?? state.immediateRedirect,
    customRedirect: customRedirect
      ? decodeURIComponent(decodeURIComponent(customRedirect))
      : state.customRedirect,
    iframe: params.get('iframe') ?? state.iframe,
    connectionType: params.get('connectionType') ?? state.connectionType,
  };
  return validParams;
};
const persistConfig = {
  key: 'queryParams',
  storage: sessionStorage,
  blacklist: ['connectionType'],
};
export default persistReducer(persistConfig, queryParams);
