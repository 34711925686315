import { useDispatch, useSelector } from 'react-redux';
import { loginSucceeded } from '../actions';
import { postData } from '../api';
import { selectDevice } from '../selectors';

const useLogin = () => {
  const dispatch = useDispatch();
  const device = useSelector(selectDevice);

  const login = async (values: any, actions: any) => {
    let body: any = {
      email: values.email,
      password: values.password,
    };
    if (device && device.token !== null) {
      body.device = device.token;
    }
    let mfa: any;
    try {
      const response = await postData('/api/v1/auth/login/', body);

      if (response.data.token === null && response.data.mfa_required_multi) {
        mfa = response.data.mfa_required_multi;
      } else {
        actions.setSubmitting(false);
        dispatch(loginSucceeded(response));
      }
    } catch (err) {
      actions.setSubmitting(false);
      // @ts-ignore
      if (err.response) {
        actions.setErrors({
          // @ts-ignore
          login: err.response.data.non_field_errors || 'Failed to login.',
        });
      } else {
        actions.setErrors({
          login: 'Failed to login.',
        });
      }
    }
    return mfa;
  };
  return login;
};

export default useLogin;
