import React from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled';

type HelloStyleProps = {
  isOnline: boolean;
  darkModeEnabled: boolean | undefined;
};

const HelloStyle = styled.div<HelloStyleProps>`
  ${(props) =>
    props.darkModeEnabled && props.isOnline === false
      ? 'color: #101010;'
      : undefined}
  margin: 0 20px 0 auto;
  padding-top: 8px;
  line-height: 1.2;
  text-align: right;
  b {
    font-size: 22px;
    font-weight: 700;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

type Props = {
  name: string;
  isOnline: boolean;
  darkModeEnabled: boolean | undefined;
};

const Hello = ({ name, isOnline, darkModeEnabled }: Props) => (
  <HelloStyle isOnline={isOnline} darkModeEnabled={darkModeEnabled}>
    <b>Hi {!name ? 'there' : name}!</b>
    <br />
    {format(new Date(), 'EEEE, do MMMM')}
  </HelloStyle>
);

export default Hello;
