import styled from '@emotion/styled';

type Props = {
  darkModeEnabled?: boolean;
  background?: string;
};

const ShadowBox = styled.div<Props>`
  box-shadow: ${(props) =>
    props.darkModeEnabled
      ? '2px 2px 8px rgba(0, 0, 0, 0.29)'
      : 'var(--box-shadow)'};
  background: ${(props) =>
    props.background ? props.background : 'var(--white)'};
  border-radius: 4px;
  padding: 20px 20px 20px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    padding: 10px;
  }
`;

export default ShadowBox;
