import React from 'react';
import styled from '@emotion/styled';
import AuthorizationPicker from './AuthorizationPicker';
import { H3, P } from '../styled/GlobalElements';
import { Authorization } from '../types/authorization';
import { useSelector } from 'react-redux';
import { selectSettings } from '../selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faEye } from '@fortawesome/free-solid-svg-icons';

type StatusProps = {
  trade: boolean;
};
const Status = styled.div<StatusProps>`
  text-align: center;
  height: min-content;
  padding: 4px 0px;
  align-self: baseline;
  color: 'black';
  background-color: var(--brand-green);
  p {
    margin: 0px;
  }
  svg {
    margin-right: 10px;
  }
`;

export const LeftOrder = styled.div`
  text-align: left;
`;

type Props = {
  type?: string;
  authorization?: Authorization;
  align?: string;
  isDemo?: boolean;
  name?: string;
  hideTitle?: boolean;
  editMode?: boolean;
};

const ConnectionUpdate = ({
  type,
  authorization,
  align,
  isDemo,
  name,
  hideTitle,
  editMode,
}: Props) => {
  const allowSelectType = type === undefined ? true : false;
  const defaultType = type === undefined ? authorization?.type : type;
  const tradeConnection = authorization?.type === 'trade';

  const settings = useSelector(selectSettings);

  const readOnly =
    authorization?.brokerage.name === 'Wealthsimple Trade' ||
    authorization?.brokerage.name === 'Schwab';

  const picker = (
    <React.Fragment>
      {!hideTitle && (
        <H3 darkModeEnabled={settings?.dark_mode_enabled}>
          Update/Refresh Connection
        </H3>
      )}
      <AuthorizationPicker
        brokerage={authorization?.brokerage.id}
        updateBrokerageAuthorizationId={authorization?.id}
        allowSelectType={allowSelectType}
        type={defaultType}
        name={name}
        isDemo={isDemo}
        allowSelect={!readOnly}
      />
    </React.Fragment>
  );

  if (!editMode) {
    return (
      <Status trade={tradeConnection}>
        {tradeConnection ? (
          <P>
            <FontAwesomeIcon icon={faExchangeAlt} /> View & Trade
          </P>
        ) : (
          <P>
            <FontAwesomeIcon icon={faEye} /> View Only
          </P>
        )}
      </Status>
    );
  } else {
    if (align && align === 'left') {
      return <LeftOrder>{picker}</LeftOrder>;
    } else {
      return <div>{picker}</div>;
    }
  }
};

export default ConnectionUpdate;
