import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { preloadRouteComponent } from '../apps/App';
import { selectSettings } from '../selectors';

type Props = {
  path: string;
  className?: string;
  children?: React.ReactNode;
};
const PreLoadLink = ({ path, children, className }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const settings = useSelector(selectSettings);

  return (
    <Link
      to={path}
      onMouseEnter={() => preloadRouteComponent(path)}
      className={className}
      style={{ color: 'settings?.dark_mode_enabled && var(--brand-white)' }}
    >
      {children}
    </Link>
  );
};

export default PreLoadLink;
