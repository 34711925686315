import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { responsiveStateReducer } from 'redux-responsive';
import auth from './auth';
import demo from './demo';
import language from './language';
import simple from './simple';
import simpleList from './simpleList';
import updateServiceWorker from './updateServiceWorker';
import online from './online';
import queryParams from './queryParams';
import version from './version';
import referral from './referral';
import tracking from './tracking';
import { SubscriptionData } from '../types/subscription';
import { GroupInfoData } from '../types/groupInfo';
import { GroupData } from '../types/group';
import { Currency } from '../types/currency';
import { Feature } from '../types/feature';
import { CurrencyRate } from '../types/currencyRate';
import { Account, Holdings } from '../types/account';
import { Authorization } from '../types/authorization';
import { Settings } from '../types/settings';
import { AssetClassDetailsType } from '../types/assetClasses';
import {
  PerformanceData,
  PerformanceCustomData,
  ReportingSettings,
  Activity,
  GpData,
} from '../types/performance';
import {
  selectedTimeframe,
  selectedAccounts,
  reportingStartDate,
  reportingEndDate,
  activitiesStartDate,
  activitiesEndDate,
} from './performance';
import { ModelPortfolioDetailsType } from '../types/modelPortfolio';
import { Goal } from '../types/goals';
import { SnapTradePartner } from '../types/SnapTrade';

const Reducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    appTime: Date.now,
    auth,
    browser: responsiveStateReducer,
    demo,
    language,
    updateServiceWorker,
    online,
    queryParams,
    version,
    selectedTimeframe,
    selectedAccounts,
    reportingStartDate,
    reportingEndDate,
    activitiesStartDate,
    activitiesEndDate,
    referral,
    tracking,
    authorizations: simple<Authorization[]>({
      baseType: 'FETCH_AUTHORIZATIONS',
      userData: true,
    }),
    emailVerified: simple<any>({
      baseType: 'FETCH_EMAIL_VERIFIED',
      userData: true,
    }),

    snapTradePartnerData: simple<SnapTradePartner>({
      baseType: 'FETCH_SNAPTRADE_PARTNER_DATA',
      userData: false,
    }),
    features: simple<Feature[]>({
      baseType: 'FETCH_FEATURES',
      userData: true,
    }),
    currencies: simple<Currency[]>({
      baseType: 'FETCH_CURRENCIES',
      userData: false,
    }),
    currencyRates: simple<CurrencyRate[]>({
      baseType: 'FETCH_CURRENCY_RATES',
      userData: false,
    }),
    groups: simple<GroupData[]>({
      baseType: 'FETCH_GROUPS',
      userData: true,
    }),
    settings: simple<Settings>({
      baseType: 'FETCH_SETTINGS',
      userData: true,
    }),
    subscription: simple<SubscriptionData>({
      baseType: 'FETCH_SUBSCRIPTION',
      userData: true,
    }),
    plans: simple<any>({
      baseType: 'FETCH_PLANS',
      userData: true,
    }),
    accounts: simple<Account[]>({
      baseType: 'FETCH_ACCOUNTS',
      userData: true,
    }),

    accountHoldings: simpleList<Holdings>({
      baseType: 'FETCH_ACCOUNT_HOLDINGS',
      userData: true,
    }),
    groupInfo: simpleList<GroupInfoData>({
      baseType: 'FETCH_GROUP_INFO',
      userData: true,
    }),
    performanceAll: simple<PerformanceData>({
      baseType: 'FETCH_PERFORMANCE_ALL',
      userData: true,
    }),
    goals: simple<Goal[]>({
      baseType: 'FETCH_GOALS',
      userData: true,
    }),
    performanceCustom: simple<PerformanceCustomData>({
      baseType: 'FETCH_PERFORMANCE_CUSTOM',
      userData: true,
    }),
    reportingSettings: simple<ReportingSettings>({
      baseType: 'FETCH_REPORTING_SETTINGS',
      userData: true,
    }),
    activities: simple<Activity[]>({
      baseType: 'FETCH_ACTIVITIES',
      userData: true,
    }),
    acbReport: simple<Activity[]>({
      baseType: 'FETCH_ACB_REPORT',
      userData: true,
    }),
    gpData: simple<GpData>({
      baseType: 'FETCH_GP_DATA',
      userData: true,
    }),
    modelAssetClasses: simple<AssetClassDetailsType[]>({
      baseType: 'FETCH_MODEL_ASSET_CLASSES',
      userData: true,
    }),
    modelPortfolios: simple<ModelPortfolioDetailsType[]>({
      baseType: 'FETCH_MODEL_PORTFOLIOS',
      userData: true,
    }),
  });

export default Reducer;
