import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions';
import { LogoutButton as StyledLogoutButton } from '../styled/LogoutButton';
import { selectSettings } from '../selectors';
import { useSelector } from 'react-redux';
import { selectIsOnline } from '../selectors/online';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const isOnline = useSelector(selectIsOnline);

  return (
    <StyledLogoutButton
      darkModeEnabled={settings?.dark_mode_enabled}
      isOnline={isOnline}
      onClick={() => dispatch(logout())}
    >
      Logout <FontAwesomeIcon icon={faSignOutAlt} />
    </StyledLogoutButton>
  );
};

export default LogoutButton;
