import { createSelector } from 'reselect';
import { AppState } from '../store';
import ms from 'milliseconds';
// import { selectLoggedIn, selectAppTime } from './index';
import shouldUpdate from '../reactors/should-update';

export const selectSnapTradePartnerDataRaw = (state: AppState) =>
  state.snapTradePartnerData;

const selectAppTime = (state: AppState) => state.appTime;
const selectLoggedIn = (state: AppState) => !!state.auth.token;

export const selectSnapTradePartnerData = createSelector(
  selectSnapTradePartnerDataRaw,
  (rawPartnerData) => {
    if (rawPartnerData.data) {
      return rawPartnerData.data;
    }
    return null;
  },
);

export const selectSnapTradePartnerDataNeedData = createSelector(
  selectLoggedIn,
  selectSnapTradePartnerDataRaw,
  selectAppTime,
  (loggedIn, rawPartnerData, time) => {
    if (!loggedIn) {
      return false;
    }
    return shouldUpdate(rawPartnerData, {
      staleTime: ms.minutes(5),
      now: time,
    });
  },
);
