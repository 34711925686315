export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const HELP_PATH = '/help';
export const CONTACT_FORM_PATH = '/contact-form';
export const RESET_PASSWORD_PATH = '/reset-password';
export const RECOVERY_PATH = '/recover-account';
export const DASHBOARD_PATH = '/dashboard';
export const GROUP_PATH = '/group';
export const SETTINGS_PATH = '/settings';
export const REFERRALS_PATH = '/referrals';
export const REPORTING_PATH = '/reporting';
export const GOALS_PATH = '/goals';
export const MY_MODELS_PATH = '/models';
