import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import App from './apps/App';
import ErrorBoundary from './components/ErrorBoundary';
import createRunLoop from './reactors/init-runloop';
import { effects } from './reactors/effects';
import store, { history } from './store';
import { pingTracking } from './seo';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/browser';
import FlagProvider, { useFlag } from '@unleash/proxy-client-react';
import { Redirect, Route, Switch } from 'react-router';
import MaintenanceModePage from './pages/MaintenanceModePage';
import Layout from './layouts/Layout';

if (
  process.env.REACT_APP_BASE_URL_OVERRIDE &&
  process.env.REACT_APP_BASE_URL_OVERRIDE === 'api.passiv.com'
) {
  Sentry.init({
    dsn: 'https://196371422ff74ef38c3e0f9632fd1710@sentry.io/1517518',
  });
} else {
  Sentry.init({
    dsn: 'https://e99a74aaa38a4a9f8a21329d9cb34d76@sentry.io/1517512',
  });
}

// initialize GA and fire first pageview
ReactGA.initialize(
  [
    {
      trackingId:
        process.env.NODE_ENV === 'production'
          ? 'UA-113321962-1'
          : 'UA-113321962-2',
      gaOptions: {},
    },
  ],
  {
    debug: process.env.NODE_ENV === 'production' ? false : true,
  },
);
ReactGA.pageview(window.location.pathname + window.location.search);

// initialize React Pixels
ReactPixel.init(
  process.env.NODE_ENV === 'production' ? '491661551763578' : '344582840073761',
);

// get GA to listen for path changes
history.listen(() => {
  pingTracking();
  ReactGA.pageview(window.location.pathname + window.location.search);
});

// get React Pixels to listen for path changes
ReactPixel.pageView();

const persistor = persistStore(store);

// create our run loop that loads our data
const runLoop = createRunLoop();
runLoop.start(store, effects);

// fire the first tracking ping
setTimeout(() => pingTracking(), 50);
// pingTracking();
const container = document.getElementById('root');
const root = createRoot(container!);

const unleashClientKey =
  process.env.REACT_APP_BASE_URL_OVERRIDE === 'api.passiv.com'
    ? 'default:production.9a41d82eedf8cc7f68d805e43855fb245159f8b94b3e00324d5e23ac'
    : 'default:development.92bcf9cb8fd5dbe722a8e21b3505143dee0cec059bc170439087e197';

const config = {
  url: 'https://flags.snaptrade.com/api/frontend',
  clientKey: unleashClientKey,
  refreshInterval: 60,
  appName: 'passiv-app',
};

const MaintenanceWrapper = ({ children }: any) => {
  const isMaintenanceMode = useFlag('maintenance-mode');
  if (isMaintenanceMode) {
    return (
      <Layout>
        <Switch>
          <Route path="/maintenance" component={MaintenanceModePage} />
          <Route path="*">
            <Redirect to="/maintenance" />
          </Route>
        </Switch>
      </Layout>
    );
  }

  return children;
};

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <FlagProvider config={config}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <MaintenanceWrapper>
              <App key={'root'} />
            </MaintenanceWrapper>
          </ConnectedRouter>
        </PersistGate>
      </FlagProvider>
    </Provider>
  </ErrorBoundary>,
);
