import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../../../api';
import { loadGroup } from '../../../actions';
import { Symbol } from '../../../types/groupInfo';
import ReactLoading from 'react-loading';
import Grid from '../../../styled/Grid';
import { selectAccountPositions } from '../../../selectors/accounts';
import { H3, P } from '../../../styled/GlobalElements';
import { useFindMatch } from '../../ModelPortfolio/utils/utils';
import { selectSettings } from '../../../selectors';

const StyledCombobox = styled(Combobox)`
  width: 100%;
  position: relative;
  z-index: 5;
  display: inline-block;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    margin-top: 16px;
  }
`;

type StyledInputProps = {
  darkModeEnabled?: boolean;
};

const StyledInput = styled(ComboboxInput)<StyledInputProps>`
  width: 70%;
  border: 1px solid;
  border-radius: 5px 5px 0px 0px;
  padding: 11px 10px;
  font-size: 18px;
  color: ${(props) => props.darkModeEnabled && 'var(--persistent-white)'};
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

type StyledPopoverProps = {
  darkMode?: boolean;
};
const StyledPopover = styled(ComboboxPopover)<StyledPopoverProps>`
  border-radius: 0px 0px 5px 5px;
  z-index: 11;
  max-height: 550px;
  overflow-y: scroll;
  color: ${(props) => props.darkMode && 'white'};

  background-color: ${(props) => props.darkMode && 'black'};

  // scroll bar styling for FireFox
  scrollbar-color: var(--brand-green) black;

  // scroll bar styling for Chrome, Edge and Safari
  ::-webkit-scrollbar {
    width: 25px;
  }
  ::-webkit-scrollbar-track {
    background: black;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--brand-green);
    border-radius: 20px;
  }
`;

export const StyledComboboxList = styled(ComboboxList)`
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  h3 {
    font-size: 22px;
    padding: 10px 20px;
  }
  @media (max-width: 900px) {
    max-width: fit-content;
    font-size: 15px;
  }
`;

export const StyledComboboxOption = styled(ComboboxOption)`
  padding: 10px 20px;
  :hover {
    background: var(--brand-green) !important;
  }
`;

const Position = styled(Grid)`
  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Description = styled.span`
  font-weight: 400;
  @media (max-width: 900px) {
    display: none;
  }
`;

const CryptoTag = styled.span`
  font-size: 15px;
  text-align: center;
  color: var(--brand-green);
`;

export const useDebouncedEffect = (
  callback: any,
  delay: number,
  deps: any[] = [],
) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

const NotFound = styled(P)`
  font-weight: 600;
  text-align: center;
  color: red;
`;

type Props = {
  value: string | undefined;
  groupId?: string;
  accountId?: string;
  forModelSecurity?: boolean;
  clearInput?: number;
  onSelect: (symbol: Symbol) => void;
};
const SymbolSelector = ({
  groupId,
  accountId,
  clearInput,
  onSelect,
}: Props) => {
  const dispatch = useDispatch();
  const [matchingSymbols, setMatchingSymbols] = useState<Symbol[]>();
  const [input, setInput] = useState('.');
  const [loading, setLoading] = useState(false);
  const allAccountPositions = useSelector(selectAccountPositions);
  const settings = useSelector(selectSettings);

  const allPositions: any = Object.values(allAccountPositions)
    .map((holding) => {
      let position;
      if (holding) {
        position = holding.data?.map((data) => {
          return data.symbol.symbol;
        });
      }
      return position;
    })
    .flat();

  const uniquePositions = Object.values(
    allPositions.reduce((acc: any, position: any) => {
      if (position && position.symbol.symbol && !acc[position.id]) {
        acc[position.id] = position;
      }
      return acc;
    }, {}),
  );

  const loadOptions = () => {
    if (input.trim() === '') {
      return;
    }
    setLoading(true);
    let symbolsURL = '';
    if (groupId) {
      symbolsURL = `/api/v1/portfolioGroups/${groupId}/symbols`;
    } else if (accountId) {
      symbolsURL = `/api/v1/accounts/${accountId}/symbols`;
    } else {
      symbolsURL = '/api/v1/symbols';
    }
    postData(symbolsURL, {
      substring: input,
    })
      .then((response) => {
        setMatchingSymbols(response.data);
        setLoading(false);
      })
      .catch(() => {
        dispatch(loadGroup({ ids: [groupId] }));
        setLoading(false);
      });
  };

  useEffect(() => {
    setInput('');
  }, [clearInput]);

  const handleSelectByTicker = (ticker: string) => {
    const tickerSplit = ticker.split(/,(.+)/);
    let symbol = tickerSplit[0].toUpperCase().trim();
    let desc: string;
    if (tickerSplit[1]) {
      desc = tickerSplit[1].trim();
    }

    const matchedSymbol = matchingSymbols?.find(
      (t) => symbol === t.symbol.trim() && desc === t.description.trim(),
    );
    if (matchedSymbol) {
      onSelect(matchedSymbol);
    } else {
      const matchedHolding = matchingHoldings?.find(
        (t: any) => symbol === t.symbol.trim() && desc === t.description.trim(),
      );
      onSelect(matchedHolding);
    }
  };

  useDebouncedEffect(
    () => {
      loadOptions();
    },
    500,
    [input],
  );
  let matchingHoldings = useFindMatch(input, uniquePositions, ['symbol']);

  const onChange = (event: any) => {
    setMatchingSymbols([]);
    setInput(event.target.value);
  };

  return (
    <StyledCombobox onSelect={handleSelectByTicker} openOnFocus>
      <StyledInput
        value={input}
        type="search"
        onChange={onChange}
        onKeyPress={(event: any) =>
          event.key === 'Enter' && handleSelectByTicker(event.target.value)
        }
        darkModeEnabled={settings?.dark_mode_enabled}
        placeholder="Search for Security..."
      />

      {loading ? (
        <StyledPopover darkMode={settings?.dark_mode_enabled}>
          <StyledComboboxList>
            <div>
              <ReactLoading
                type="bubbles"
                width={50}
                color="var(--brand-green)"
              />
            </div>
          </StyledComboboxList>
        </StyledPopover>
      ) : (
        <StyledPopover darkMode={settings?.dark_mode_enabled}>
          <StyledComboboxList>
            {matchingHoldings && matchingHoldings.length > 0 && (
              <>
                <H3 darkModeEnabled={settings?.dark_mode_enabled}>
                  Your Holdings
                </H3>
                {matchingHoldings.map((position: any, index: any) => {
                  const value = `${position?.symbol}, ${position?.description}`;
                  return (
                    <StyledComboboxOption key={index} value={value}>
                      <Position columns="2fr 5fr 2fr">
                        {
                          // @ts-ignore
                          position?.symbol
                        }
                        <Description>
                          {
                            // @ts-ignore
                            position?.description
                          }
                        </Description>
                        {
                          // @ts-ignore
                          position.type.code === 'crypto' ? (
                            <CryptoTag>Crypto</CryptoTag>
                          ) : (
                            <CryptoTag></CryptoTag>
                          )
                        }
                      </Position>
                    </StyledComboboxOption>
                  );
                })}
              </>
            )}
            {matchingSymbols && matchingSymbols.length > 0 && (
              <>
                <H3>Other Securities</H3>
                {matchingSymbols.map((option, index) => {
                  const value = `${option.symbol}, ${option.description}`;
                  return (
                    <StyledComboboxOption key={index} value={value}>
                      <Position columns="2fr 5fr 2fr">
                        {option.symbol}
                        <Description>{option.description}</Description>
                        {option.type.code === 'crypto' ? (
                          <CryptoTag>Crypto</CryptoTag>
                        ) : (
                          <CryptoTag></CryptoTag>
                        )}
                      </Position>
                    </StyledComboboxOption>
                  );
                })}
              </>
            )}
            {matchingHoldings.length === 0 &&
              matchingSymbols?.length === 0 &&
              !loading && <NotFound>Symbol Not Found</NotFound>}
          </StyledComboboxList>
        </StyledPopover>
      )}
    </StyledCombobox>
  );
};

export default SymbolSelector;
