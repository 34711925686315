import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from '../components/Header';
import SlideMenu from '../components/SlideMenu';
import GlobalStyle from '../styled/global';
import ScrollHelper from '../components/ScrollHelper';
import ReconnectMessage from '../components/ReconnectMessage';
import MaintenanceMessage from '../components/MaintenanceMessage';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettings } from '../selectors';
import { useDebouncedEffect } from '../components/PortfolioGroupTargets/TargetBar/SymbolSelector';
import { loadEmailVerified } from '../actions';
import { selectIsEmailVerified } from '../selectors/emailVerified';
import { selectShowSecureApp } from '../selectors/app';
import { useFlag } from '@unleash/proxy-client-react';

const Container = styled.div`
  display: flex;
`;

const Main = styled.main`
  min-height: 90vh;
  position: relative;
  padding: 114px 30px 30px;
  max-width: 1550px;
  width: 100%;
  @media (max-width: 900px) {
    padding: 95px 15px;
  }
`;

interface Props {
  children: JSX.Element;
}

const AppLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const isEmailVerified = useSelector(selectIsEmailVerified);
  const showSecureApp = useSelector(selectShowSecureApp);
  const [counter, setCounter] = useState(1);
  const isMaintenanceMode = useFlag('maintenance-mode');

  // check settings every 10 seconds if email is not verified or there's a pending email
  setTimeout(() => {
    const newCounter = counter + 1;
    if (
      settings &&
      !settings?.pre_email_validation_user &&
      (!isEmailVerified || settings?.pending_email_address !== '')
    ) {
      setCounter(newCounter);
    }
  }, 10000);

  useDebouncedEffect(
    () => {
      if (showSecureApp) dispatch(loadEmailVerified());
    },
    200,
    [counter],
  );

  return (
    <div>
      <GlobalStyle />
      <Header />
      <Container>
        {!isMaintenanceMode && <SlideMenu />}
        <Main>
          <MaintenanceMessage />
          <ReconnectMessage />
          {children}
        </Main>
      </Container>
      <ScrollHelper />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default AppLayout;
