import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../../styled/Button';
import { H1, H3, P } from '../../styled/GlobalElements';
import { useDispatch, useSelector } from 'react-redux';
import { updateOnboardingStep } from '../../actions/onboarding';
import { selectSettings } from '../../selectors';

const Container = styled.div`
  > h1 {
    line-height: 57px;
  }
  iframe {
    padding: 30px 0px;
  }
`;

export const Welcome = styled(H1)`
  margin-top: 75px;
`;

export const Description = styled(P)`
  font-size: 24px;
  margin-bottom: 40px;
`;

const Steps = styled.div`
  margin: 40px 0px 50px;
`;

type StepProps = {
  darkModeEnabled?: boolean;
};

const Step = styled.div<StepProps>`
  ${(props) => (props.darkModeEnabled ? 'color: #101010' : '')}
  margin-bottom: 28px;
  span {
    margin-right: 12px;
    background-color: white;
    padding: 2px 8px;
    border-radius: 3rem;
  }
  p {
    margin-left: 40px;
  }
`;

const Start = styled(Button)`
  padding: 20px 45px 22px;
`;

const Intro = () => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);

  return (
    <Container>
      <Welcome darkModeEnabled={settings?.dark_mode_enabled}>
        Welcome to Passiv
      </Welcome>
      <Description>Let's get you set up!</Description>
      <Steps>
        {steps.map((step, index) => {
          return (
            <Step>
              <H3 darkModeEnabled={settings?.dark_mode_enabled}>
                <span
                  style={
                    settings?.dark_mode_enabled
                      ? { color: '#101010' }
                      : undefined
                  }
                >
                  {index + 1}
                </span>
                {step.name}
              </H3>
              <P>{step.description}</P>
            </Step>
          );
        })}
      </Steps>
      <Description>
        Once you're set up, Passiv will help you follow your investment targets!
        Start growing your wealth now!
      </Description>

      <Start onClick={() => dispatch(updateOnboardingStep(1, settings))}>
          Get Started
      </Start>
    </Container>
  );
};

export default Intro;

export const steps = [
  {
    name: 'Connect Brokerage',
    mobile: 'Connect',
    description: 'It’s easy! Take 30 seconds to connect your account.',
  },
  {
    name: 'Choose Membership',
    mobile: 'Membership',
    description:
      'Starting at $0 for life, choose the package that is right for you!',
  },

  {
    name: 'Set Targets',
    mobile: 'Setup',
    description:
      'Assign a percentage allocation to each asset in your portfolio.',
  },
];
