import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import { useSelector } from 'react-redux';
import { ReactLoadingWithText } from '.';
import { selectAccounts } from '../../selectors/accounts';
import { selectCurrencies } from '../../selectors/currencies';
import { Button } from '../../styled/Button';
import { A, H2, H3, P, Table } from '../../styled/GlobalElements';
import Grid from '../../styled/Grid';
import Number from '../Number';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { selectSettings } from '../../selectors';

const Container = styled.div`
  h2 {
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 20px;
  }
`;

const Order = styled(Grid)`
  text-align: center;
  background-color: var(--brand-light-green);
  padding: 15px;
`;

const Trade = styled.div`
  margin: 50px 0px;
  div {
    text-align: center;
  }
`;

const TradeHeaders = styled(Grid)`
  @media (max-width: 900px) {
    display: grid;
    grid-gap: 10px;
  }
`;

const TradeRows = styled(Grid)`
  @media (max-width: 900px) {
    display: grid;
    grid-gap: 10px;
  }
`;

const ActionContainer = styled(Table)`
  margin-top: 40px;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const CancelBtn = styled.button`
  color: var(--brand-blue);
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  svg {
    margin-right: 10px;
  }
`;

const ExecuteBtn = styled.div``;

type Props = {
  orderImpact: any;
  secondOrderImpact: any;
  currencyCode?: string;
  executeTrade: (id: string) => void;
  executeOcoTrade: (id1: string, id2: string) => void;
  cancelTrade: () => void;
};

const TradeImpact = ({
  orderImpact,
  secondOrderImpact,
  currencyCode,
  executeTrade,
  executeOcoTrade,
  cancelTrade,
}: Props) => {
  const settings = useSelector(selectSettings);
  const accounts = useSelector(selectAccounts);
  const currencies = useSelector(selectCurrencies);

  const [executingTrade, setExecutingTrade] = useState(false);

  const filteredAccount = accounts.find(
    (account) => account.id === orderImpact.trade_impacts[0]?.account,
  );

  return (
    <Container>
      <H2 darkModeEnabled={settings?.dark_mode_enabled}>Order Summary </H2>
      {secondOrderImpact && (
        <P>
          The trade listed below will be placed as
          <strong> {orderImpact.trade.order_type} order </strong>
          on your brokerage account.
        </P>
      )}
      {secondOrderImpact && (
        <P>
          The trades listed below will be placed as
          <strong> {orderImpact.trade.order_type} order </strong> and{' '}
          <strong> {secondOrderImpact.trade.order_type} order </strong> on your
          brokerage account. If/when one order fills, the other will be
          cancelled.
        </P>
      )}

      <Order columns="0.5fr 1fr 0.5fr 1fr 1fr">
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Type </H3>
          <P>{orderImpact.trade.action}</P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Security </H3>
          <P>{orderImpact.trade.symbol.symbol}</P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Units </H3>
          <P>{orderImpact.trade.units}</P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Price </H3>
          <P>
            <CurrencyFlag currency={currencyCode} size="md" /> {currencyCode}
            <Number
              value={orderImpact.trade.price}
              currency={currencyCode === null ? undefined : currencyCode}
            />
          </P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Amount </H3>
          <P>
            <CurrencyFlag currency={currencyCode} size="md" /> {currencyCode}
            <Number
              value={orderImpact.trade.units * orderImpact.trade.price}
              currency={currencyCode === null ? undefined : currencyCode}
            />
          </P>
        </div>
      </Order>
      {secondOrderImpact && (
        <Order columns="0.5fr 1fr 0.5fr 1fr 1fr">
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Type </H3>
            <P>{secondOrderImpact.trade.action}</P>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Security </H3>
            <P>{secondOrderImpact.trade.symbol.symbol}</P>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Units </H3>
            <P>{secondOrderImpact.trade.units}</P>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Price </H3>
            <P>
              <CurrencyFlag currency={currencyCode} size="md" /> {currencyCode}
              <Number
                value={secondOrderImpact.trade.price}
                currency={currencyCode === null ? undefined : currencyCode}
              />
            </P>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Amount </H3>
            <P>
              <CurrencyFlag currency={currencyCode} size="md" /> {currencyCode}
              <Number
                value={
                  secondOrderImpact.trade.units * secondOrderImpact.trade.price
                }
                currency={currencyCode === null ? undefined : currencyCode}
              />
            </P>
          </div>
        </Order>
      )}

      <Trade>
        <P>
          <strong>
            {filteredAccount?.name} ({filteredAccount?.number})
          </strong>{' '}
          is estimated to be charged the following fees:
        </P>
        <TradeHeaders columns="2fr 2fr 2fr">
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>
              Remaining cash:
            </H3>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>
              Trade commissions:
            </H3>
          </div>
          <div>
            <H3 darkModeEnabled={settings?.dark_mode_enabled}>Forex fees: </H3>
          </div>
        </TradeHeaders>
        <TradeRows columns="2fr 2fr 2fr">
          {orderImpact.trade_impacts.map((impact: any, index: number) => {
            const currencyCode = currencies?.find(
              (currency) => currency.id === impact.currency,
            )!.code;
            return (
              <>
                <div>
                  <CurrencyFlag currency={currencyCode} size="md" />
                  {currencyCode}
                  <Number
                    value={impact.remaining_cash}
                    currency={currencyCode === null ? undefined : currencyCode}
                  />
                </div>
                <div>
                  <CurrencyFlag currency={currencyCode} size="md" />
                  {currencyCode}
                  <Number
                    value={impact.estimated_commissions}
                    currency={currencyCode === null ? undefined : currencyCode}
                  />
                </div>
                <div>
                  <CurrencyFlag currency={currencyCode} size="md" />
                  {currencyCode}
                  <Number
                    value={impact.forex_fees}
                    currency={currencyCode === null ? undefined : currencyCode}
                  />
                </div>
              </>
            );
          })}
        </TradeRows>
      </Trade>
      <div>
        <H3 darkModeEnabled={settings?.dark_mode_enabled}>Note:</H3>
        <ul>
          <li>
            <P>
              Market orders may result in the price paid or received to be
              different from the last price quoted before the order was placed.
              {'  '}
              <A
                href="https://www.investopedia.com/terms/m/marketorder.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </A>
            </P>
          </li>
          <li>
            <P>
              <A
                href="https://www.investopedia.com/terms/e/ecn.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exchange and ECN fees
              </A>
              ,
              <A
                href="https://www.investopedia.com/terms/s/secfee.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                SEC fees
              </A>{' '}
              and for{' '}
              <A
                href="https://www.investopedia.com/terms/a/adr.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                ADRs
              </A>{' '}
              annual custody fees may apply. Commissions may vary if your order
              is filled over multiple days. Borrow fees may apply if you hold a
              short investment overnight.
            </P>
          </li>
        </ul>
      </div>
      <ActionContainer>
        <CancelBtn onClick={cancelTrade}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
          Go Back
        </CancelBtn>
        <ExecuteBtn>
          <Button
            disabled={executingTrade}
            onClick={() => {
              if (secondOrderImpact) {
                executeOcoTrade(
                  orderImpact.trade.id,
                  secondOrderImpact.trade.id,
                );
              } else {
                executeTrade(orderImpact.trade.id);
              }
              setExecutingTrade(true);
            }}
          >
            {executingTrade ? (
              <ReactLoadingWithText>
                Placing Order
                <ReactLoading
                  type="bubbles"
                  color="var(--white)"
                  height={25}
                  width={25}
                />
              </ReactLoadingWithText>
            ) : (
              'Place Trade'
            )}
          </Button>
        </ExecuteBtn>
      </ActionContainer>
    </Container>
  );
};

export default TradeImpact;
