import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { H1 } from '../styled/GlobalElements';
import { Description } from '../components/Onboarding/Intro';
import ShadowBox from '../styled/ShadowBox';
import styled from '@emotion/styled';

const Container = styled(ShadowBox)`
  text-align: center;
`;

const MaintenanceModePage = () => {
  return (
    <Container background="var(--brand-light-orange)">
      <div>
        <FontAwesomeIcon icon={faGear} size="4x" color="orange" />
        <H1>Currently under maintenance</H1>
        <Description>
          We will be back as soon as possible. Thanks for your patience.
        </Description>
      </div>
    </Container>
  );
};

export default MaintenanceModePage;
