import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import whiteLogoRaster from '../../assets/images/logo-no-name-white.png';
import whiteLogoRaster2x from '../../assets/images/logo-no-name-white@2x.png';
import logoRaster from '../../assets/images/logo-no-name.png';
import logoRaster2x from '../../assets/images/logo-no-name@2x.png';
import { selectName, selectLoggedIn, selectSettings } from '../../selectors';
import Hello from './Hello';
import Offline from './Offline';
import Demo from './Demo';
import { selectIsOnline } from '../../selectors/online';
import { selectIsDemo } from '../../selectors';
import LogoutButton from '../LogoutButton';
import { useFlag } from '@unleash/proxy-client-react';

type StyledHeaderProps = {
  isOnline: boolean;
  darkModeEnabled: boolean | undefined;
};

const StyledHeader = styled.header<StyledHeaderProps>`
  position: fixed;
  width: 100%;
  padding: 10px 20px 10px;
  padding-left: 222px;
  background: ${(props) =>
    props.darkModeEnabled
      ? props.isOnline
        ? '#232629'
        : 'var(--brand-light-orange)'
      : props.isOnline
      ? '#fff'
      : 'var(--brand-light-orange)'};
  border-bottom: ${(props) =>
    !props.isOnline && '5px solid var(--brand-orange);'};
  box-shadow: ${(props) =>
    props.darkModeEnabled
      ? '2px 2px 8px rgba(0, 0, 0, 0.29);'
      : '2px 2px 8px rgba(190, 190, 190, 0.29);'};
  z-index: 4;
  nav {
    background: inherit;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    padding: 9px 8px 4px 75px;
  }
`;
const Logo = styled.header`
  margin-top: 2px;
  img {
    max-height: 55.5px;
  }
  @media (max-width: 900px) {
    margin-top: 0;
  }
`;

const Header = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const name = useSelector(selectName);
  const isOnline = useSelector(selectIsOnline);
  const isDemo = useSelector(selectIsDemo);
  const settings = useSelector(selectSettings);
  const isMaintenanceMode = useFlag('maintenance-mode');

  return (
    <StyledHeader
      isOnline={isOnline}
      darkModeEnabled={settings?.dark_mode_enabled}
    >
      <nav>
        <Logo>
          <Link to="/">
            <img
              src={settings?.dark_mode_enabled ? whiteLogoRaster : logoRaster}
              srcSet={`${
                settings?.dark_mode_enabled ? whiteLogoRaster2x : logoRaster2x
              } 2x`}
              alt="Passiv Logo"
            />
          </Link>
        </Logo>

        {isOnline ? isDemo && <Demo /> : !isMaintenanceMode && <Offline />}

        {loggedIn && !isMaintenanceMode && (
          <>
            <Hello
              name={name}
              isOnline={isOnline}
              darkModeEnabled={settings?.dark_mode_enabled}
            />
            <LogoutButton />
          </>
        )}
      </nav>
    </StyledHeader>
  );
};

export default Header;
