import { getData, postData } from '../api';
import { ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  formattedLastQuarter,
  formattedToday,
} from '../components/Performance/DatePickers';

export const loadPerformanceAll: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = (accountNumbers) => {
  return (dispatch) => {
    dispatch(fetchPerformanceAllStart());
    getData(`/api/v1/performance/all?accounts=${accountNumbers}`)
      .then((response) => {
        dispatch(fetchPerformanceAllSuccess(response));
      })
      .catch((error) => dispatch(fetchPerformanceAllError(error)));
  };
};

export const loadPerformanceCustom: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = (accountNumbers, startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchPerformanceCustomStart());
    getData(
      `/api/v1/performance/custom?startDate=${startDate}&endDate=${endDate}&accounts=${accountNumbers}`,
    )
      .then((response) => {
        dispatch(fetchPerformanceCustomSuccess(response));
      })
      .catch((error) => dispatch(fetchPerformanceCustomError(error)));
  };
};

export const loadReportingSettings: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = () => {
  return (dispatch) => {
    dispatch(fetchReportingSettingsStart());
    getData('/api/v1/performance/settings/')
      .then((response) => {
        dispatch(fetchReportingSettingsSuccess(response));
      })
      .catch((error) => dispatch(fetchReportingSettingsError(error)));
  };
};

export const updateReportingSettings: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = (settings) => {
  return (dispatch) => {
    dispatch(updateReportingSettingsStart());
    postData('/api/v1/performance/settings/', settings)
      .then((response) => {
        dispatch(fetchReportingSettingsSuccess(response));
        dispatch(updateReportingSettingsSuccess(response));
      })
      .catch((error) => dispatch(updateReportingSettingsError(error)));
  };
};

export const loadAllActivities: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = () => {
  return (dispatch) => {
    dispatch(fetchActivitiesStart());
    getData('/api/v1/activities/')
      .then((response) => {
        dispatch(fetchActivitiesSuccess(response));
      })
      .catch((error) => dispatch(fetchActivitiesError(error)));
  };
};

export const loadDefaultActivities: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = () => {
  const startDate = formattedLastQuarter();
  const endDate = formattedToday();
  return (dispatch) => {
    dispatch(fetchActivitiesStart());
    getData('/api/v1/activities/', { startDate, endDate })
      .then((response) => {
        dispatch(fetchActivitiesSuccess(response));
      })
      .catch((error) => dispatch(fetchActivitiesError(error)));
  };
};

export const loadFilteredActivities: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchActivitiesStart());
    getData('/api/v1/activities/', { startDate, endDate })
      .then((response) => {
        dispatch(fetchActivitiesSuccess(response));
      })
      .catch((error) => dispatch(fetchActivitiesError(error)));
  };
};

export const loadGpData: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = () => {
  return (dispatch) => {
    dispatch(fetchGpDataStart());
    getData('/api/v1/gpData/')
      .then((response) => {
        dispatch(fetchGpDataSuccess(response));
      })
      .catch((error) => dispatch(fetchGpDataError(error)));
  };
};

export const loadAcbReport: ActionCreator<ThunkAction<
  void,
  any,
  any,
  Action<any>
>> = (year) => {
  return (dispatch) => {
    dispatch(fetchAcbReportStart());
    getData(
      `/api/v1/acbReport?year=${year}`,
    )
      .then((response) => {
        dispatch(fetchAcbReportSuccess(response));
      })
      .catch((error) => dispatch(fetchAcbReportError(error)));
  };
};

export const fetchPerformanceAllStart: ActionCreator<Action> = () => ({
  type: 'FETCH_PERFORMANCE_ALL_START',
});

export const fetchPerformanceAllSuccess: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_PERFORMANCE_ALL_SUCCESS',
  payload,
});

export const fetchPerformanceAllError: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_PERFORMANCE_ALL_ERROR',
  payload,
});

export const fetchPerformanceCustomStart: ActionCreator<Action> = () => ({
  type: 'FETCH_PERFORMANCE_CUSTOM_START',
});

export const fetchPerformanceCustomSuccess: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_PERFORMANCE_CUSTOM_SUCCESS',
  payload,
});

export const fetchPerformanceCustomError: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_PERFORMANCE_CUSTOM_ERROR',
  payload,
});

export const fetchReportingSettingsStart: ActionCreator<Action> = () => ({
  type: 'FETCH_REPORTING_SETTINGS_START',
});

export const fetchReportingSettingsSuccess: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_REPORTING_SETTINGS_SUCCESS',
  payload,
});

export const fetchReportingSettingsError: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_REPORTING_SETTINGS_ERROR',
  payload,
});

export const updateReportingSettingsStart: ActionCreator<Action> = () => ({
  type: 'UPDATE_REPORTING_SETTINGS_START',
});

export const updateReportingSettingsSuccess: ActionCreator<Action> = (
  payload,
) => ({
  type: 'UPDATE_REPORTING_SETTINGS_SUCCESS',
  payload,
});

export const updateReportingSettingsError: ActionCreator<Action> = (
  payload,
) => ({
  type: 'UPDATE_REPORTING_SETTINGS_ERROR',
  payload,
});

export const setSelectedTimeframe: ActionCreator<Action> = (timeframe) => ({
  type: 'SET_SELECTED_TIMEFRAME',
  timeframe,
});

export const setSelectedAccounts: ActionCreator<Action> = (accounts) => ({
  type: 'SET_SELECTED_ACCOUNTS',
  accounts,
});

export const setStartDate: ActionCreator<Action> = (startDate) => ({
  type: 'SET_REPORTING_START_DATE',
  startDate,
});

export const setEndDate: ActionCreator<Action> = (endDate) => ({
  type: 'SET_REPORTING_END_DATE',
  endDate,
});

export const setActivitiesEndDate: ActionCreator<Action> = (endDate) => ({
  type: 'SET_ACTIVITIES_END_DATE',
  endDate,
});

export const setActivitiesStartDate: ActionCreator<Action> = (startDate) => ({
  type: 'SET_ACTIVITIES_START_DATE',
  startDate,
});

export const fetchActivitiesStart: ActionCreator<Action> = () => ({
  type: 'FETCH_ACTIVITIES_START',
});

export const fetchActivitiesSuccess: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_ACTIVITIES_SUCCESS',
  payload,
});

export const fetchActivitiesError: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_ACTIVITIES_ERROR',
  payload,
});

export const fetchGpDataStart: ActionCreator<Action> = () => ({
  type: 'FETCH_GP_DATA_START',
});

export const fetchGpDataSuccess: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_GP_DATA_SUCCESS',
  payload,
});

export const fetchGpDataError: ActionCreator<Action> = (payload) => ({
  type: 'FETCH_GP_DATA_ERROR',
  payload,
});

export const fetchAcbReportStart: ActionCreator<Action> = () => ({
  type: 'FETCH_ACB_REPORT_START',
});

export const fetchAcbReportSuccess: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_ACB_REPORT_SUCCESS',
  payload,
});

export const fetchAcbReportError: ActionCreator<Action> = (
  payload,
) => ({
  type: 'FETCH_ACB_REPORT_ERROR',
  payload,
});
