import { postData } from '../api';

export const restrictionTypes = [
  {
    id: 'RETAIN_MIN',
    name: 'Retain at least',
    description: 'Keep at least this amount of cash in your account',
  },
  {
    id: 'ALLOCATE_MAX',
    name: 'Allocate at most',
    description: 'Use at most this much cash when purchasing assets',
  },
];

export const Truncate = (name: string, truncateBy: number) => {
  let truncatedName = name;
  if (name.length > truncateBy) {
    truncatedName = name.slice(0, truncateBy) + '...';
  }
  return truncatedName;
};

// a function to check if user registered more than equal 16 days ago since the deploy date (2023-06-22) and haven't verified their email
export const checkIfUserRegisteredMoreThan16DaysAgoSinceNewEVSDeploy = (
  registeredDate: string | undefined,
): boolean => {
  if (registeredDate === undefined) return false;
  const registeredOn = new Date(registeredDate);
  const today = new Date();
  const difference = today.getTime() - registeredOn.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  const deployDate = new Date('2023-06-22');
  const difference2 = registeredOn.getTime() - deployDate.getTime();
  const TotalDays2 = Math.ceil(difference2 / (1000 * 3600 * 24));
  if (TotalDays >= 16 && TotalDays2 >= 0) {
    return true;
  } else {
    return false;
  }
};

export const startConnection = async ({
  slug,
  connectionType,
  updateBrokerageAuthId,
}: {
  slug?: string;
  connectionType?: string;
  updateBrokerageAuthId?: string | null;
}) => {
  let snapTradeLoginLink: any = null;
  try {
    let loginData: {
      broker?: string;
      reconnect?: string;
      connectionType?: string;
    } = {};
    if (slug) {
      loginData = {
        broker: slug,
      };
    }
    if (updateBrokerageAuthId) {
      loginData = {
        reconnect: updateBrokerageAuthId,
      };
    }

    if (connectionType) loginData.connectionType = connectionType;

    snapTradeLoginLink = await postData('/api/v1/loginlink', loginData);
    snapTradeLoginLink = snapTradeLoginLink.data.redirectURI;
  } catch (err) {
    console.log(err);
  }
  return snapTradeLoginLink;
};
