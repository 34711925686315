import { useDispatch } from 'react-redux';
import { loginSucceeded, registerFailed } from '../actions';
import { postData } from '../api';
import ReactPixel from 'react-facebook-pixel';

const useRegistration = () => {
  const dispatch = useDispatch();

  const registration = async (values: any, actions: any) => {
    postData('/api/v1/auth/register/', {
      name: values.name,
      email: values.email,
      password: values.password,
      referralCode: values.referralCode,
      hearAboutUs: values.hearAboutUs,
      other: values.other,
    })
      .then((response) => {
        // login
        actions.setSubmitting(false);
        dispatch(loginSucceeded(response));
        ReactPixel.track('CompleteRegistration');
      })
      .catch((error) => {
        let errors: any = {};
        if (error.response.data.errors.password) {
          errors.password = error.response.data.errors.password.join(' ');
        }
        if (error.response.data.errors.email) {
          errors.email = error.response.data.errors.email.join(' ');
        }
        actions.setErrors(errors);
        actions.setSubmitting(false);
        dispatch(registerFailed(error));
      });
  };
  return registration;
};

export default useRegistration;
