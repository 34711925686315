import React from 'react';
import '@reach/tooltip/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from './Tooltip';
import { useSelector } from 'react-redux';
import {
  selectLoggedIn,
  selectDisabledAuthorizations,
  selectMaintenanceBrokerages,
} from '../selectors';
import { selectAccounts } from '../selectors/accounts';
import { selectPathname } from '../selectors/router';
import styled from '@emotion/styled';
import ConnectionUpdate from './ConnectionUpdate';
import { P } from '../styled/GlobalElements';
import NotificationMessage from './NotificationMessage';

const ReconnectContainer = styled.div`
  font-size: 18px;
  margin-top: 50px;
  border-bottom: 1px solid grey;
  &:last-child {
    border-bottom: none;
  }
  width: 70%;
  padding: 3px;
  * {
    vertical-align: middle;
  }
`;

const ReconnectPanel = styled.div`
  display: inline-block;
  margin-left: 20px;
  @media (max-width: 900px) {
    padding-top: 10px;
    display: inline;
    text-align: left;
  }
`;

const Title = styled.span`
  font-weight: 600;
`;

const ReconnectMessage = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const disabledAuthorizations = useSelector(selectDisabledAuthorizations);
  const maintenanceBrokerages = useSelector(selectMaintenanceBrokerages);
  const accounts = useSelector(selectAccounts);
  const pathname = useSelector(selectPathname);

  const idSet = new Set(maintenanceBrokerages?.map((obj: any) => obj.id));
  const disabledOnly = disabledAuthorizations?.filter(
    (obj) => !idSet.has(obj.brokerage.id),
  );

  if (loggedIn && !pathname.startsWith('/oauth')) {
    if (disabledOnly && disabledOnly.length > 0) {
      return (
        <NotificationMessage
          error={true}
          title={'Action required: Reconnect'}
          alwaysOpen={true}
        >
          <div>
            <P>
              Passiv has lost its connection to your brokerage account. We
              apologize for the inconvenience, but it's a simple fix! Just click
              the button below to reconnect.
            </P>
            {disabledOnly.map((authorization) => {
              let selectedAccounts = accounts.filter(
                (account) =>
                  account.brokerage_authorization === authorization.id,
              );

              let accountString = `${
                selectedAccounts.length
              } Accounts: ${selectedAccounts
                .map((account) => account.name)
                .join(', ')}`;
              return (
                <ReconnectContainer>
                  <ReconnectPanel>
                    <ConnectionUpdate
                      authorization={authorization}
                      type={authorization.type}
                      hideTitle={true}
                      name="Reconnect"
                      align="left"
                      editMode={true}
                    />
                  </ReconnectPanel>
                  <ReconnectPanel>
                    <Tooltip label={accountString}>
                      <span>
                        <Title>Name:</Title>&nbsp;{authorization.name}&nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                    </Tooltip>
                    <ReconnectPanel>
                      <span>
                        <Title>Brokerage:</Title>&nbsp;
                        {authorization.brokerage.name}
                      </span>
                    </ReconnectPanel>
                  </ReconnectPanel>
                </ReconnectContainer>
              );
            })}
          </div>
        </NotificationMessage>
      );
    }
  }
  return null;
};

export default ReconnectMessage;
