import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import ReactLoading from 'react-loading';
import styled from '@emotion/styled';
import { Table } from '../styled/GlobalElements';
import { ReactLoadingWithText } from './ManualTrade';
import { startConnection } from '../common';
import { selectBrokerages } from '../selectors';
import { SnapTradeReact } from 'snaptrade-react';
import { Button, SmallButton } from '../styled/Button';
import { Error } from '../types/SnapTrade';
import { Brokerage } from '../types/brokerage';

const Container = styled(Table)`
  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const SelectType = styled.select`
  text-align: center;
  padding: 5px 35px 5px 10px;
  max-width: 170px;
  max-height: 35px;
  border: 1px solid;
  font-size: 20px;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.5em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.7em auto, 100%;
  :disabled {
    background-image: none;
    background-color: white;
  }
`;

const SubmitBtn = styled(SmallButton)`
  margin-left: 20px;
`;

type Props = {
  allowSelect?: boolean;
  allowSelectType?: boolean;
  updateBrokerageAuthorizationId?: string | null;
  brokerage?: string;
  type?: string;
  name?: string;
  isDemo?: boolean;
};

const AuthorizationPicker = ({
  allowSelect = true,
  allowSelectType = true,
  updateBrokerageAuthorizationId = null,
  brokerage: brokerageId = '',
  type: _type = '',
  name = 'Connect',
  isDemo,
}: Props) => {
  const dispatch = useDispatch();
  const brokerages = useSelector(selectBrokerages);
  const [type, setType] = useState(_type || ''); // Provide a default value
  const [loading, setLoading] = useState(false);
  const [isSnapTradeModalOpen, setIsSnapTradeModalOpen] = useState(false);
  const [snapTradeLoginLink, setSnapTradeLoginLink] = useState('');

  let brokerageObj: Brokerage | undefined; // Declare the variable outside the conditional block

  if (brokerages) {
    brokerageObj = brokerages.find((x) => x.id === brokerageId);
  }

  const startAuthorization = async () => {
    setLoading(true);

    try {
      if (brokerageObj) {
        const loginLink = await startConnection({
          slug: brokerageObj.slug,
          connectionType: type,
          updateBrokerageAuthId: updateBrokerageAuthorizationId,
        });
        setSnapTradeLoginLink(loginLink);
        setIsSnapTradeModalOpen(true);
      }

      setLoading(false);
    } catch (error) {
      // Handle the error
    }
  };

  let types = null;
  if (brokerages && brokerageId) {
    const brokerageObj = brokerages.find((x) => x.id === brokerageId);
    if (brokerageObj) {
      types = brokerageObj.authorization_types.map((type: any) => {
        return (
          <option key={type.type} value={type.type}>
            {type.type === 'trade' ? 'View & Trade' : 'View Only'}
          </option>
        );
      });
    }
  }

  const authSuccess = (authId: string) => {
    dispatch(
      push(`/connection-complete/${brokerageObj?.name || ''}?authId=${authId}`),
    );
  };

  const authError = (data: Error) => {
    const { errorCode } = data;
    if (errorCode == null || !(['1066', '1067'].includes(errorCode))) {
      dispatch(
        push(
          `/connection-complete/${brokerageObj?.name || ''}?errorCode=${
            data.errorCode
          }`,
        ),
      );
    }
  };

  let submitButton = <Button disabled>Connect</Button>;
  if (brokerageId && type) {
    submitButton = (
      <SubmitBtn onClick={startAuthorization} disabled={isDemo || loading}>
        {loading ? (
          <ReactLoadingWithText>
            {name}{' '}
            <ReactLoading type="spin" width={20} height={20} color="inherit" />
          </ReactLoadingWithText>
        ) : (
          name
        )}
      </SubmitBtn>
    );
  }

  return (
    <>
      <Container>
        <div>
          {allowSelectType && (
            <SelectType
              disabled={!allowSelect}
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              {types}
            </SelectType>
          )}
        </div>

        {submitButton}
      </Container>
      <SnapTradeReact
        key="authorization-picker"
        loginLink={snapTradeLoginLink}
        isOpen={isSnapTradeModalOpen}
        closeOnOverlayClick={false}
        close={() => {
          setIsSnapTradeModalOpen(false);
        }}
        onSuccess={authSuccess}
        onError={authError}
      />
    </>
  );
};

export default AuthorizationPicker;
