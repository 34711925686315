import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getData } from '../api';
import { selectSettings } from '../selectors';
import { selectIsEmailVerified } from '../selectors/emailVerified';
import { P } from '../styled/GlobalElements';
import { NotificationContainer } from './NotificationMessage';

const Email = styled.span`
  color: var(--brand-blue);
  padding: 0px 8px;
  font-weight: 600;
`;

const ResendButton = styled.button`
  color: var(--brand-blue);
  font-size: inherit;
  font-weight: 400;
  text-decoration: underline;
  letter-spacing: 0.8px;
`;
type Props = {
  view:
    | 'Onboarding'
    | 'Trade'
    | 'Connect'
    | 'Dashboard'
    | 'Reporting'
    | 'Portfolio';
  showInBox?: boolean;
};

const EmailNotVerifiedWarning = ({ view, showInBox = true }: Props) => {
  const settings = useSelector(selectSettings);
  const emailVerified = useSelector(selectIsEmailVerified);
  const [emailSent, setEmailSent] = useState(false);

  const resendEmailVerification = () => {
    if (!emailVerified) {
      getData('/api/v1/resendVerificationEmail/ ')
        .then(() => {
          setEmailSent(true);
          setTimeout(() => {
            setEmailSent(false);
          }, 60000);
        })
        .catch(() => toast.error('Request failed!'));
    }
  };

  let descriptionCompletion = '.';
  switch (view) {
    case 'Onboarding':
      descriptionCompletion = 'to continue with the onboarding process.';
      break;
    case 'Connect':
      descriptionCompletion = 'to connect a brokerage account.';
      break;
    case 'Trade':
      descriptionCompletion = 'to enable Trading feature.';
      break;
    case 'Reporting':
      descriptionCompletion = 'to enable Reporting';
      break;
    default:
      break;
  }

  const content = (
    <>
      <P>
        Please verify your email address using the link sent to
        <Email>{settings?.email}</Email>
        {descriptionCompletion}
      </P>
      Haven't received the confirmation email?
      <ResendButton onClick={resendEmailVerification} disabled={emailSent}>
        {emailSent
          ? 'Confirmation email has been re-sent'
          : 'Re-send confirmation email'}
      </ResendButton>
    </>
  );

  return (
    <>
      {showInBox ? (
        <NotificationContainer
          error={true}
          darkModeEnabled={settings?.dark_mode_enabled}
        >
          {content}
        </NotificationContainer>
      ) : (
        <div>{content}</div>
      )}
    </>
  );
};

export default EmailNotVerifiedWarning;
