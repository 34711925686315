import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { OrderContainer, ConfirmContainer } from '.';
import { P, H2 } from '../../styled/GlobalElements';
import { Button } from '../../styled/Button';
import ConnectionUpdate from '../ConnectionUpdate';
import { loadGroupAndAccounts } from '../../actions';
import { selectDashboardGroups } from '../../selectors/groups';
import { selectSettings } from '../../selectors';
import { push } from 'connected-react-router';
import PreLoadLink from '../PreLoadLink';
import { CONTACT_FORM_PATH, SETTINGS_PATH } from '../../apps/Paths';
import EmailNotVerifiedWarning from '../EmailNotVerifiedWarning';

const ErrorDetail = styled(P)`
  padding-left: 20px;
`;

const ErrorAttributeSpan = styled.span`
  font-weight: 600;
  padding-right: 10px;
`;

type Props = {
  error: any;
  closeWidget: () => void;
  groupId: string;
  backgroundColor?: string;
};

const ErrorMessage = ({
  error,
  closeWidget,
  groupId,
  backgroundColor,
}: Props) => {
  const groups = useSelector(selectDashboardGroups);
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();

  const reloadGroup = () => {
    dispatch(loadGroupAndAccounts({ ids: [groupId] }));
  };

  const getReadBrokerageAuthorization = () => {
    let group = groups.find((g) => g.id === groupId);
    if (!group || !group.brokerage_authorizations) {
      return;
    }
    return group.brokerage_authorizations.find((a) => a.type === 'read');
  };

  let errorMsg;

  switch (error.code) {
    case '1014':
      errorMsg = (
        <>
          <H2 darkModeEnabled={settings?.dark_mode_enabled}>
            Passiv needs trading permissions to place orders
          </H2>
          <P darkModeEnabled={settings?.dark_mode_enabled}>
            This portfolio group does not have trade permissions and therefore
            can't be used to place orders.
          </P>
          <P darkModeEnabled={settings?.dark_mode_enabled}>
            Reconnect with full trade permissions to place orders with Passiv:
          </P>
          <ConfirmContainer>
            <ConnectionUpdate
              authorization={getReadBrokerageAuthorization()}
              type="trade"
              hideTitle={true}
              name="Reconnect"
              align="left"
              editMode={true}
            />
          </ConfirmContainer>
        </>
      );
      break;
    case '1019':
      errorMsg = (
        <>
          <H2 darkModeEnabled={settings?.dark_mode_enabled}>
            <FontAwesomeIcon icon={faClock} /> Markets are closed
          </H2>
          <P>{error.detail}</P>
          <ErrorDetail>
            <ErrorAttributeSpan>Exchange:</ErrorAttributeSpan>
            <span>
              {error.meta.exchange.code} ({error.meta.exchange.name})
            </span>
          </ErrorDetail>
          <ErrorDetail>
            <ErrorAttributeSpan>Reason:</ErrorAttributeSpan>
            <span>{error.meta.reason}</span>
          </ErrorDetail>
          <ConfirmContainer>
            <Button onClick={closeWidget}>Okay</Button>
          </ConfirmContainer>
        </>
      );
      break;
    case '1068':
      errorMsg = (
        <>
          <H2 darkModeEnabled={settings?.dark_mode_enabled}>
            Cannot place trade
          </H2>
          <P>{error.detail}</P>
          <ConfirmContainer>
            <Button onClick={closeWidget}>Okay</Button>
          </ConfirmContainer>
        </>
      );
      break;
    case '1020':
      errorMsg = (
        <>
          <H2>Upgrade to access one-click trades</H2>
          <P>
            One-click Trades are only available to Elite subscribers. You can
            upgrade your account to use this feature.
            <PreLoadLink path={CONTACT_FORM_PATH}>Contact support </PreLoadLink>
            if you're already a paid subscriber and you're still receiving this
            message.
          </P>
          <Button onClick={() => dispatch(push('/settings'))}>Upgrade</Button>
        </>
      );
      break;
    case '1022':
      errorMsg = (
        <>
          <H2>Order has already been placed, can't place again</H2>
          <P>
            Our records show that this order has already been placed, so Passiv
            will not attempt to place it again. Please refresh the orders or
            <PreLoadLink path={CONTACT_FORM_PATH}>
              {' '}
              contact support{' '}
            </PreLoadLink>
            if this persists.
          </P>
          <Button onClick={reloadGroup}>Refresh</Button>
        </>
      );
      break;
    case '1033':
      errorMsg = (
        <>
          <H2>Order type unsupported on one or more exchanges</H2>
          <P>
            We are unable to place your orders because some trades are on
            exchanges that only accept limit orders. Passiv uses market orders
            by default.
          </P>
          <P>
            Consider using limit orders by enabling them on your
            <PreLoadLink path={SETTINGS_PATH}> settings</PreLoadLink> page.
            Alternatively, you can always place orders manually on your
            brokerage.
          </P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '1042':
      errorMsg = (
        <>
          <H2>Trading functionality is temporarily disabled</H2>
          <P>
            We're sorry, we can't place your order at the moment. Trading
            functionality has been temporarily disabled while we address an
            issue with international holiday handling. If you need to place
            orders immediately, you can still do so at your brokerage.
          </P>
          <P>Thanks for your patience!</P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '1047':
      errorMsg = (
        <>
          <H2>Can't place orders due to existing open orders</H2>
          <P>
            It looks like you have some open orders on one or more of your
            brokerage accounts. Open orders can cause unexpected behavior while
            Passiv attempts to place a batch of orders, so we didn't try to
            place the orders on your account.
          </P>
          <P>
            You can fix this by going to{' '}
            <PreLoadLink path={`/group/${groupId}/orders`}>
              Orders tab
            </PreLoadLink>{' '}
            or logging into your brokerage account and closing any open orders
            that you may have. If that's not the case, please{' '}
            <PreLoadLink path={CONTACT_FORM_PATH}>contact support</PreLoadLink>{' '}
            and let us know!
          </P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '1048':
      errorMsg = (
        <>
          <H2>Canceled due to possible negative cash balance</H2>
          <P>Your account's net cash</P>
          <P>
            You can fix this by going to
            <PreLoadLink path={`/group/${groupId}/orders`}>
              Orders tab
            </PreLoadLink>
            or logging into your brokerage account and closing any open orders
            that you may have. If that's not the case, please
            <PreLoadLink path={CONTACT_FORM_PATH}>
              {' '}
              contact support{' '}
            </PreLoadLink>
            and let us know!
          </P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '1135':
      errorMsg = (
        <>
          <H2>Order cannot be processed</H2>
          <P>
            This is a registered account. You can't submit an order for this
            type of security in a registered account.
          </P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '1136':
      errorMsg = (
        <>
          <H2>Order cannot be processed</H2>
          <P>This security cannot be traded in your account.</P>
          <Button onClick={closeWidget}>Okay</Button>
        </>
      );
      break;
    case '0000':
      errorMsg = (
        <>
          <H2>Order cannot be processed</H2>
          <P>
            We're currently experiencing difficulties processing your order.
            Please check your brokerage account to confirm if your order was
            successfully placed before attempting to place the order again
            <PreLoadLink path={CONTACT_FORM_PATH}>
              {' '}
              contact support{' '}
            </PreLoadLink>
            if this persists.
          </P>
          <ConfirmContainer>
            <Button onClick={closeWidget}>Okay</Button>
          </ConfirmContainer>
        </>
      );
      break;
    case 'email_not_verified':
      errorMsg = <EmailNotVerifiedWarning view="Trade" showInBox={false} />;
      break;
    default:
      errorMsg = (
        <>
          <H2>Order cannot be processed</H2>
          <P>
            We are sorry but we are having difficulties processing your order at
            this time. Please wait a moment and try again or
            <PreLoadLink path={CONTACT_FORM_PATH}>
              {' '}
              contact support{' '}
            </PreLoadLink>
            if this persists.
          </P>
          <ConfirmContainer>
            <Button onClick={closeWidget}>Okay</Button>
          </ConfirmContainer>
        </>
      );
  }
  return (
    <OrderContainer
      background={backgroundColor}
      darkModeEnabled={settings?.dark_mode_enabled}
    >
      {errorMsg}
    </OrderContainer>
  );
};

export default ErrorMessage;
