import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { postData } from '../api';
import {
  selectCurrentGroupExcludedAssets,
  selectCurrentGroupId,
  selectCurrentGroupInfo,
  selectCurrentGroupSettings,
} from '../selectors/groups';
import { SmallButton, SmallTransparentButton } from '../styled/Button';
import { A, P } from '../styled/GlobalElements';
import Grid from '../styled/Grid';
import styled from '@emotion/styled';
import { loadGroup, loadModelPortfolios } from '../actions';
import { toast } from 'react-toastify';
import {
  selectModelPortfolios,
  selectModelUseByOtherGroups,
} from '../selectors/modelPortfolios';
import { Position } from '../types/groupInfo';
import NotificationMessage from './NotificationMessage';
import ReactLoading from 'react-loading';
import SelectAssetClassModal from './SelectAssetClassModal';

export const ReactLoadingDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListOfAssets = styled.ul`
  font-size: 1.3rem;
  text-align: left;
  margin: 30px;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 15px;
  align-items: center;
  @media (max-width: 900px) {
    margin-bottom: 30px;
    button {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

const SymbolGrid = styled(Grid)`
  @media (max-width: 900px) {
    margin-bottom: 10px;
  }
`;

const Symbol = styled.div`
  font-weight: 600;
`;

const Description = styled.div``;

const DontShowBtn = styled.div`
  text-align: left;
  margin-top: 50px;
`;

const ExcludeAll = styled.div`
  margin-bottom: 20px;
  button {
    min-width: 200px;
    color: var(--brand-green);
    border-color: var(--brand-green);
  }
  @media (max-width: 900px) {
    button {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`;

const AddAll = styled.div`
  button {
    min-width: 170px;
    color: var(--white);
    background-color: var(--brand-green);
  }
  @media (max-width: 900px) {
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const BoldSpan = styled.span`
  font-weight: 900;
`;

type Props = {
  targets: Position[];
};
const NewAssetsDetected = ({ targets }: Props) => {
  const dispatch = useDispatch();
  const [loadingId, setLoadingId] = useState('');
  const [allLoading, setAllLoading] = useState(false);
  const [showChooseAssetClass, setShowChooseAssetClass] = useState(false);
  const [targetSelected, setTargetSelected] = useState<Position>();

  const currentGroupInfo = useSelector(selectCurrentGroupInfo);
  const currentGroupId = useSelector(selectCurrentGroupId);
  const settings = useSelector(selectCurrentGroupSettings);
  const modelPortfolios = useSelector(selectModelPortfolios);
  const modelUseByOtherGroups = useSelector(selectModelUseByOtherGroups);
  const excludedAssets = useSelector(selectCurrentGroupExcludedAssets);

  const handleExcludeAsset = (targetId: string, excludeAll: boolean) => {
    let excluded: string[] = excludedAssets;
    let api = `/api/v1/portfolioGroups/${currentGroupId}/excludedassets/`;
    if (!securityBased) {
      api = `/api/v1/portfolioGroups/${currentGroupId}/assetClassExcludeAssets`;
    }
    if (excludeAll) {
      setAllLoading(true);
      const allTargetIds = targets.map((target) => target.symbol?.id);
      excluded = excluded.concat(allTargetIds);
    } else {
      setLoadingId(targetId);
      excluded.push(targetId);
    }
    postData(api, excluded)
      .then(() => {
        dispatch(loadGroup({ ids: [currentGroupId] }));
      })
      .catch(() => {
        toast.error('Exclusion operation failed');
      });
  };
  const modelId = currentGroupInfo?.model_portfolio?.id;
  const model = modelPortfolios.filter(
    (mdl) => mdl.model_portfolio.id === modelId,
  );
  const securityBased = model[0]?.model_portfolio.model_type === 0;

  const handleAddToModel = (target: any, addAll: boolean) => {
    if (addAll) {
      setAllLoading(true);
    } else {
      setLoadingId(target.symbol?.id);
    }

    if (securityBased) {
      if (addAll) {
        let targetss = targets
          .filter((target) => !target.excluded)
          .map((target) => {
            return {
              symbol: target.symbol,
              percent: '0',
            };
          });
        model[0].model_portfolio_security = [
          ...model[0].model_portfolio_security,
          ...targetss,
        ];
      } else {
        const newTarget = {
          symbol: target.symbol,
          percent: '0',
        };
        model[0].model_portfolio_security = [
          ...model[0].model_portfolio_security,
          newTarget,
        ];
      }
      postData(`/api/v1/modelPortfolio/${modelId}`, model[0])
        .then((res) => {
          postData(
            `api/v1/portfolioGroups/${currentGroupId}/modelPortfolio/${modelId}`,
            {},
          )
            .then(() => {
              dispatch(loadGroup({ ids: [currentGroupId] })); // need to load all groups to have an updated list of groups using a model in my models page
              dispatch(loadModelPortfolios());
              toast.success(
                `${addAll ? 'All symbols' : target.symbol?.symbol} added to '${
                  model[0].model_portfolio.name
                }'`,
              );
            })
            .catch((err) => {
              if (err.response) {
                toast.error(err.response.data.detail);
              } else {
                toast.error('Cannot add security to model');
              }
            });
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.detail);
          } else {
            toast.error('Cannot add security to model');
          }
        });
    }
  };

  if (!settings?.show_warning_for_new_assets_detected) {
    return <></>;
  } else {
    return (
      <NotificationMessage
        error={false}
        title={'New Assets Detected'}
        alwaysOpen={false}
      >
        <div>
          {allLoading ? (
            <ReactLoadingDiv>
              <ReactLoading
                type="bubbles"
                width={60}
                color="var(--brand-green)"
              />
            </ReactLoadingDiv>
          ) : (
            <>
              <P>
                We noticed that you added the following securities in your
                account and since these assets are not included in your model
                portfolio, they may impact your portfolio accuracy or trade
                calculations. You can either add them to your model portfolio*
                or exclude them.
              </P>
              <ListOfAssets>
                {targets.length > 1 && (
                  <Grid columns="1fr 180px 200px">
                    <div></div>
                    {securityBased && (
                      <AddAll>
                        <SmallButton
                          onClick={() => handleAddToModel(targets, true)}
                        >
                          Add All
                        </SmallButton>
                      </AddAll>
                    )}
                    <ExcludeAll>
                      <SmallTransparentButton
                        onClick={() => handleExcludeAsset('', true)}
                      >
                        Exclude All
                      </SmallTransparentButton>
                    </ExcludeAll>
                  </Grid>
                )}
                {targets?.map((target) => {
                  if (target.excluded) {
                    return false;
                  }
                  if (target.symbol?.id === loadingId) {
                    return (
                      <ReactLoadingDiv>
                        <ReactLoading
                          type="bubbles"
                          width={60}
                          color="var(--brand-green)"
                        />
                      </ReactLoadingDiv>
                    );
                  }
                  return (
                    <StyledGrid
                      columns="1fr 180px 200px"
                      key={target.symbol?.id}
                    >
                      <SymbolGrid columns="180px auto">
                        <Symbol>{target.symbol?.symbol}</Symbol>
                        <Description>{target.symbol?.description}</Description>
                      </SymbolGrid>
                      {!modelUseByOtherGroups ? (
                        <SmallButton
                          onClick={() => {
                            if (securityBased) {
                              handleAddToModel(target, false);
                            } else {
                              setShowChooseAssetClass(true);
                              setTargetSelected(target);
                              setLoadingId(target.symbol?.id);
                            }
                          }}
                        >
                          Add to Model
                        </SmallButton>
                      ) : (
                        <div></div>
                      )}
                      <SmallTransparentButton
                        onClick={() =>
                          handleExcludeAsset(target.symbol?.id, false)
                        }
                      >
                        Exclude
                      </SmallTransparentButton>
                    </StyledGrid>
                  );
                })}
              </ListOfAssets>

              <small>
                {securityBased ? (
                  <>
                    * The securities get added with
                    <BoldSpan>a weighting of 0%</BoldSpan>. Scroll down and
                    click Edit Model to change their allocation.
                  </>
                ) : (
                  <>
                    * You can add securities to any asset classes available but
                    if the asset class is not part of the model, it gets added
                    to model with <BoldSpan>a weighting of 0%</BoldSpan>. Scroll
                    down and click Edit Model to change their allocation.
                  </>
                )}
              </small>
              <DontShowBtn>
                <A
                  onClick={() =>
                    dispatch(push(`/group/${currentGroupId}/settings`))
                  }
                >
                  Do not want to see this message again? Turn it off in your
                  portfolio settings.
                </A>
              </DontShowBtn>
            </>
          )}
          <SelectAssetClassModal
            showChooseAssetClass={showChooseAssetClass}
            closeModal={() => setShowChooseAssetClass(false)}
            targetSelected={targetSelected}
          />
        </div>
      </NotificationMessage>
    );
  }
};

export default NewAssetsDetected;
