import React from 'react';
import styled from '@emotion/styled';
import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TransparentButton } from '../../styled/Button';
import { H2, H3, P } from '../../styled/GlobalElements';
import Grid from '../../styled/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectCurrentGroupId } from '../../selectors/groups';
import { selectSettings } from '../../selectors';
import { loadAccounts } from '../../actions';

const OrderDetails = styled(Grid)`
  margin-top: 20px;
  padding: 10px;
`;

type StatusProps = {
  status: string;
};
const Status = styled(P)<StatusProps>`
  font-weight: 600;
  color: ${(props) =>
    props.status === 'EXECUTED'
      ? 'var(--brand-green)'
      : props.status === 'ACCEPTED'
      ? 'var(--brand-blue)'
      : 'var(--brand-orange)'};
  svg {
    margin-right: 5px;
  }
`;

const ActionContainer = styled.div`
  margin-top: 20px;
  button {
    margin-bottom: 20px;
  }
`;

type Props = {
  data: any;
  reset: () => void;
};

const ExecutedOrderSummary = ({ data, reset }: Props) => {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const groupId = useSelector(selectCurrentGroupId);

  if (!data) {
    return <> </>;
  }
  const {
    order_type,
    action,
    filled_quantity,
    open_quantity,
    execution_price,
    limit_price,
    status,
    universal_symbol,
  } = data;
  const limitOrder = order_type === 'Limit';
  return (
    <div>
      <H2 darkModeEnabled={settings?.dark_mode_enabled}>Order details</H2>
      <OrderDetails columns="repeat(5, 1fr)">
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Action </H3>
          <P>{action}</P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Security </H3>
          <P>{universal_symbol.symbol}</P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Units </H3>
          <P>
            {+filled_quantity > 0
              ? Number(filled_quantity).toFixed(2)
              : Number(open_quantity).toFixed(2)}
          </P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Price </H3>
          <P>
            {+execution_price > 0
              ? execution_price
              : Number(limit_price).toFixed(2)}
          </P>
        </div>
        <div>
          <H3 darkModeEnabled={settings?.dark_mode_enabled}>Status </H3>
          <Status status={status}>
            <FontAwesomeIcon
              icon={
                status === 'EXECUTED' || status === 'ACCEPTED'
                  ? faCheckCircle
                  : faExclamationTriangle
              }
            />
            {status}
          </Status>
        </div>
      </OrderDetails>
      <ActionContainer>
        <Button onClick={reset}>Place another order</Button>
        {limitOrder && (
          <TransparentButton
            onClick={() => {
              dispatch(loadAccounts());
              dispatch(push(`/group/${groupId}/orders`));
            }}
          >
            View All Open Orders
          </TransparentButton>
        )}
      </ActionContainer>
    </div>
  );
};

export default ExecutedOrderSummary;
