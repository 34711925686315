import { createSelector } from 'reselect';
import ms from 'milliseconds';
import shouldUpdate from '../reactors/should-update';
import { selectLoggedIn, selectAppTime } from './index';
import { AppState } from '../store';

export const selectIsEmailVerifiedRaw = (state: AppState) => {
  return state.emailVerified;
};

export const selectIsEmailVerified = createSelector(
  selectIsEmailVerifiedRaw,
  (rawEmailVerified) => {
    if (rawEmailVerified.data) {
      return rawEmailVerified.data.email_verified;
    }
    return false;
  },
);
export const selectIsEmailVerifiedNeedData = createSelector(
  selectLoggedIn,
  selectIsEmailVerifiedRaw,
  selectAppTime,
  (loggedIn, rawEmailVerified, time) => {
    if (!loggedIn) {
      return false;
    }
    return shouldUpdate(rawEmailVerified, {
      staleTime: ms.minutes(10),
      now: time,
    });
  },
);
