import styled from '@emotion/styled';

type LogoutButtonProps = {
  darkModeEnabled?: boolean;
  isOnline?: boolean;
};

export const LogoutButton = styled.button<LogoutButtonProps>`
  color: ${(props) =>
    props.darkModeEnabled && !props.isOnline
      ? '#101010;'
      : props.darkModeEnabled
      ? '#ffffff;'
      : undefined};
  background: ${(props) => (props.darkModeEnabled ? '#0e0f10' : 'none')};
  border: 1px solid var(--brand-grey);
  padding: 15px 18px 16px;
  margin: 0 5px 5px 5px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.8px;
  border-radius: 2px;
`;
