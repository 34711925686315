import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../selectors';

type Props = {
  decimalPlaces?: number;
  currency?: string | null;
  percentage?: boolean;
  isTrade?: boolean;
  forcePlusMinus?: boolean;
  value: number;
  privacyMode?: boolean;
  className?: string;
};

const Number = (props: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const settings = useSelector(selectSettings);

  let decimalPlaces = 1;
  if (props.decimalPlaces !== undefined) {
    decimalPlaces = props.decimalPlaces;
  }
  let language = 'en-US';
  if (typeof window !== 'undefined') {
    language = navigator.language;
  }
  let prefix = null;
  if (props.currency !== undefined && props.currency !== null) {
    if (props.decimalPlaces === undefined) {
      if (!props.isTrade) {
        decimalPlaces = 2;
      } else {
        let defaultDecimalPlaces = 2;
        let pieces = props.value.toString().split('.');
        let actualDecimalPlaces = 0;
        if (pieces.length > 1) {
          actualDecimalPlaces = pieces[1].length;
        }
        decimalPlaces = Math.max(defaultDecimalPlaces, actualDecimalPlaces);
      }
    }
  }
  let postfix = null;
  if (props.percentage) {
    postfix = '%';
  }
  if (props.forcePlusMinus) {
    if (props.value >= 0) {
      prefix = '+';
    }
  }
  let numberProps: {
    maximumFractionDigits: number;
    minimumFractionDigits: number;
    currency?: string;
    style?: string;
    currencyDisplay?: string;
  } = {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  };
  if (props.currency !== undefined && props.currency !== null) {
    numberProps.style = 'currency';
    numberProps.currencyDisplay = 'narrowSymbol';
    numberProps.currency = props.currency;
  }
  let formattedNumber = null;
  try {
    formattedNumber = new Intl.NumberFormat(language, numberProps).format(
      props.value,
    );
  } catch (e) {
    numberProps.currencyDisplay = 'symbol';
    formattedNumber = new Intl.NumberFormat(language, numberProps).format(
      props.value,
    );
  }
  const originalNumber = formattedNumber;

  if (props.privacyMode) {
    formattedNumber = formattedNumber
      .split('')
      .map((number) => {
        if (+number > 0) return '*';
        else {
          return '';
        }
      })
      .join('');
  }

  return (
    <span
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={props.className}
    >
      {prefix}
      {isHovered && settings?.privacy_mode_hover
        ? originalNumber
        : formattedNumber}
      {postfix}
    </span>
  );
};

export default Number;
